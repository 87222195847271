.task-list {
    // max-width: 600px;
    margin: 0 auto 1em auto;



    &__progress {
        font-size: 0.8em;
        color: transparentize($base-color, 0.6);
        margin-bottom: 0.2em;
    }

    &__title {
        font-weight: bold;
        font-size: 1.5em;
        margin: 1rem 0 0.4rem 0;
    }

    &__group {
        margin-bottom: 1.5em;



        &__title {
            margin-bottom: 0.5em;
            font-weight: bold;
            padding: 0.5em;
            border-radius: 0.15em;

            &:hover {
                background-color: transparentize($base-color, 0.9);
            }
        }

        &__item {
            cursor: move;
            border: 1px solid transparentize($base-color, 1);
            line-height: 1.3em;
            
            border-bottom:1px solid transparentize($base-color, 0.75);

            transition: 0.2s background-color;
            
            padding: 0.5em;
            // border-radius: 0.15em;
            // align-items: baseline !important;
            display: flex !important;
            position: relative;
            overflow: hidden;

            &:last-child {
                border-bottom:0;
            }
            &__check {
                font-size: 1.2em;
            }

            &:hover {
                background-color: transparentize($base-color, 0.9)!important;
            }
        }

        &--done &__item{
			background-color: transparent;	
			margin:0;
        }
        &--done {
        	color: transparentize($base-color, 0.3);
            margin-top: 2em;
            font-size: 0.8em;
        }

        &--done &__title {
            margin-bottom: 0em;
        }
    }

}