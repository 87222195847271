$base-font-family: "Inter", sans-serif;
$base-bg-color: #1c1e26;
$base-color: #ffffff;
//$base-bg-color: #fff;
//$base-color: #000;
$secondary-color: #008bcc;
$base-font-size: 16px;
$grid-space: 10px;

$danger-color: #dc3545; // piros
$success-color: #28a745; // zold
$warning-color: #ffc107; // sarga
$info-color: $secondary-color; // purple

$font-weight-bold: 600;
$font-weight-light: 300;

@mixin btn($element) {
    #{$element}__btn {
        cursor: pointer;
        transition: 0.3s opacity;
        opacity: 0.5;
    }

    #{$element}__btn:hover {
        opacity: 1;
    }
}

@mixin scrollbar($element) {
    #{$element}{
        scrollbar-color: transparentize($base-color, 0.90) transparent;
        // scrollbar-width: thin;
    }

    #{$element}::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: transparent;
    }

    #{$element}::-webkit-scrollbar {
        width: 12px;
        background-color: transparent;
    }

    #{$element}::-webkit-scrollbar-thumb {
        border-radius: 10px;

        background-color: transparentize($base-color, 0.90);
    }
}

$colors: (
    "default": #fff,
    "dk": #fff,
    "monk": #000,
    "rogue": #000,
    "dh": #fff,
    "bubu": #000,
    "pink": #000,
    "gelato": #000,
);
$backgrounds: (
    "default": #008bcc,
    "dk": #c41f3b,
    "monk": #00ff96,
    "rogue": #fff569,
    "dh": #a330c9,
    "bubu": #07daf8,
    "pink": #f1c5ce,
    "gelato": #9ed227,
);

@mixin template($class, $property, $transparentize: 0, $tmp: "", $transparentize_tmp: 0) {
    @each $name, $hexa in $backgrounds {
        .template--#{$name} #{$class} {
            @if $transparentize {
                #{$property}: transparentize($hexa, $transparentize);
            } @else {
                #{$property}: #{$hexa};
            }
        }
    }

    @if $tmp != "" {
        @each $name, $hexa in $colors {
            .template--#{$name} #{$class} {
                @if $transparentize_tmp {
                    #{$tmp}: transparentize($hexa, $transparentize_tmp);
                } @else {
                    #{$tmp}: #{$hexa};
                }
            }
        }
    }
}
