.tasklists-item {
	.task-list__group__item__check {
		font-size:0.8rem;
	}
	.task-list__group__title {
		font-size:0.8rem;	
		padding:0;
		margin:0;
	}
	.task-list__group__item:hover,.task-list__group__title:hover {
		background-color: transparent!important;
	}
	.task-list__group__item {

		font-size:0.8rem;
		margin:0;
		padding:0;
		background-color: transparent;
	}
}