.modal__layout {
    background-color: transparentize($base-bg-color, 0.05);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // display: none;
    justify-content: center;
    align-items: flex-start;
    padding:1rem;
    overflow-y:auto;
    z-index:10;
    display:flex;
}

.modal {
    border-radius: 0.15rem;
    margin-top: 4rem;
    min-width: 600px;
    max-width: 1000px;
    min-height: 100px;
    padding: 1rem;
    border: 1px solid darken($base-bg-color, 20%);
    border-top: 2px solid transparentize($secondary-color, 0.3);
    background-color: $base-bg-color;

    &__head {
        font-weight: bold;
        font-size: 1.2rem;
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        border-bottom: 1px solid darken($base-bg-color, 20%);
        margin-bottom: 1rem;
    }
    &__footer {
        display: flex;
        justify-content: space-between;
        padding-top: 1rem;
        border-top: 1px solid darken($base-bg-color, 20%);
        margin-top: 1rem;
    }

    &__close-btn {
        margin-left: 1rem;
        border-radius: 0.15rem;
        color: transparentize($base-color, 0.6);
        transition: 0.25s color;
        cursor: pointer;
    }
    &__close-btn:hover {
        border-radius: 0.15rem;
        color: transparentize($base-color, 0.3);
    }

    &__body {
    }
}
@include template(".modal", "border-top-color");


@media (max-width: 768px) {
    .modal {
        min-width:auto;
        width:100%;
        
    }
  }