.leftnav {
	width:200px;
	padding:1rem;
	&__item {

		a {
			padding:1rem;
			display: inline-block;
			width:100%;
			height:100;
		}
		&:hover {
			background-color: rgba(255,255,255,0.6);
		}		
	}
}