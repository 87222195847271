.card {
	padding:1rem;
	background-color: transparentize( $base-color, 0.97 );

	border-radius:0.15rem;
	border-top:2px solid transparentize( $secondary-color, 0.3);
	
	&:hover {
		border-top-color: transparentize( $secondary-color, 0);
	}
	.card {
		border:none;
	}
	&__img {
		margin:2rem 0rem;
	}
	&__img img{
		max-height:100px;

	}
	&__body {
		line-height:1.3rem;

		
			
			&--infolist__item {
				padding:2px 0;
				color:transparentize( $base-color, 0.4);
				display:flex;
				justify-content: space-between;
				font-size:0.8rem;
			}

	}
	&__head {
		font-weight: $font-weight-bold;
		letter-spacing: 0.5px;
		font-size:1.1rem;
		margin-bottom:0.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
	
}
@include template('.card','border-top-color')