.comments {
	margin: 0 auto;
	&__count {
		font-weight: 600;
		font-size: 1rem;
		margin-bottom: 0.5rem;
		color: transparentize($base-color, 0.6);
	}
}
.comment {
	&__textarea {
		

	}
	&__send {
		
		&__cont {
		
			
		}
	}
}
.comment__item {
	margin-bottom: 1rem;

	&__body {
		line-height: 1.2rem;
		font-size: 0.9rem;
	}
	&__footer {
		font-size: 0.8rem;
		text-align: right;
		margin-top: 0.5rem;
	}


}

@include template(".comments .card", "border-top-color", 0.7);
@include template(".comments .btn", "background-color", 0.4);
