.notification {
    &__cont {
        position: fixed;
        top:4rem;
        right:1rem;

        .alert {
            font-size: 0.9em;
            padding:0.5rem 0.75rem;
        }
    }
}