.container {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding-right: $grid-space;
    padding-left: $grid-space;
}

.container-fluid {

    margin: 0 auto;
    padding-right: $grid-space;
    padding-left: $grid-space;
}

.row {
    margin-right: -$grid-space;
    margin-left: -$grid-space;
    display: flex;
    flex-wrap: wrap;

    @for $i from 1 through 12 {
        .col-md-#{$i} {
            position: relative;
            width: 100%;
            padding: 0 $grid-space;
            flex: 0 0 100%;
            max-width: 100%;
        }

        .offset-md-#{$i} {
            margin: 0;
        }
    }
    
        @for $i from 1 through 12 {
            .col-#{$i} {
                max-width: 100% * ($i/12);
                flex: 0 0 100% * ($i/12);
            }

            .offset-#{$i} {
                margin-left: 100% * ($i/12);
            }
        }

}


@media (min-width: 768px) {
    .row {
        @for $i from 1 through 12 {
            .col-md-#{$i} {
                max-width: 100% * ($i/12);
                flex: 0 0 100% * ($i/12);
            }

            .offset-md-#{$i} {
                margin-left: 100% * ($i/12);
            }
        }

    }
}