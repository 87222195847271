.dropdown {
    position: relative;

    &__content {
        z-index:1;
        border-radius:0.15rem;
        position: absolute;
        top: 100%;
        right: 0;
        font-weight: bold;
        color:$base-color;
        display: none;
        font-size: 1rem;
        border: 1px solid darken($base-bg-color, 20%);
        border-top: 2px solid transparentize($secondary-color, 0.3);
        background-color: $base-bg-color;
        text-align: left;
    }

    &--show &__content {
        display: block;
    }

    &__item {
        padding:1rem;
        white-space: nowrap;
        background-color: $base-bg-color;
        &:hover {
            background-color: transparentize($base-color, 0.9);
        }
    }
}

@include template(".dropdown__content", 'border-top-color');