

@include template('.badge','background-color',0,'color');

.badge {
    
    padding:0em 0.6em;
    font-size: 0.85em;
    border-radius:0.5em;
    background-color: $secondary-color;
    color:$base-color;
    
    &--sm {
        padding:0em 0.5em;
        font-size: 0.8em;
        border-radius:0.5em;
    }
    &--faded {
        background-color: transparent!important;
        color: transparentize($base-color, .7)!important;
    }    


}

