.kanban {
  display: flex;
  margin: 0 auto;
  flex: 1;
  overflow-x: scroll;
  min-height:75vh;
}
@include scrollbar('.kanban');

.kanban > div:last-child .kanban__column__head {
  border-right: 0px;
}

.kanban__column {
  width: 300px;
  margin: 0.5em;
  background-color: transparentize($base-color, 0.97);

  
}
.kanban__column__items {
  height:65vh;
  overflow-y: auto;
  
}
@include scrollbar('.kanban__column__items');


.kanban > div:last-child .kanban__column {
  margin-right:0;
}
.kanban > div:first-child .kanban__column {
  margin-left:0;
}

.kanban__column__head {
  font-weight: bold;
  padding: 0.5em 1em;
  border-top:2px solid  transparentize($secondary-color, 0.3);
  // background-color: transparentize( $base-color, 0.8 );
}
@include template('.kanban__column__head','border-top-color');

.kanban__column__new {
  padding: 0.5em;
  cursor: pointer;
  margin: 0.5em;
  color: transparentize($base-color, 0.8);
  
}
.kanban__column__new:hover {
  color: transparentize($base-color, 0.4);
}
.kanban__column__new--opened {
  margin: 0em;
}
.kanban__column__item {
  // @extend .card;
  border-radius:0.2em;
  box-shadow: 0 1px 0 rgba(9,30,66,.25) ;
  background-color: transparentize($base-color, 0.95);
  padding: 0.5em;
  margin: 0.5em;
  font-size:0.95em;
  line-height: 1.33em;
  cursor: pointer;
  &:hover {
    background-color: transparentize($base-color, 0.9);
  }
  &__head {
    margin-bottom:0.5em;
    color: transparentize($base-color, 0.1);
    font-weight: bold;

  }

  &__footer:empty {display: none;}
  &__footer {
    margin-top:0.5em;
    // font-size: 0.9em;
    color: transparentize($base-color, 0.4);
  }
}


@media (max-width: 768px) {
  .kanban {
    display:block;
  }
  .kanban__column{
    width:100%;
    margin-left:0;
    margin-bottom:3em;
  } 
  .kanban__column__items{
    height:auto;
  }
}