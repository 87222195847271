.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    border: none;
    color: $base-color;
    background-color: transparent;
    transition: 0.25s color;
    transition: 0.25s background-color;
    outline:none;
    
    &:hover {
        color: transparentize($base-color, .3);
    }

    &--faded {
        color: transparentize($base-color, 0.6);

        &:hover {
            color: transparentize($base-color, 0.3);
        }
    }



    &--sm {
        padding: .2rem .5rem;
        font-size: 0.9rem;
    }

    &--primary {
        color: #fff;
        background-color: $secondary-color;

        &.btn--faded {
            color: transparentize($base-bg-color, 0.3);
            background-color: transparentize($secondary-color, 0.3);
        }

        &:hover {
            color: transparentize(#fff, .2);
            background-color: transparentize($secondary-color, .2);
        }
    }
        &--danger {
        color: #fff;
        background-color: $danger-color;

        &.btn--faded {
            color: transparentize(#fff, 0.3);
            background-color: transparentize($danger-color, 0.3);
        }

        &:hover {
            color: transparentize(#fff, .2);
            background-color: transparentize($danger-color, .2);
        }
    }

    &--success {
        color: #fff;
        background-color: $success-color;

        &.btn--faded {
            color: transparentize(#fff, 0.3);
            background-color: transparentize($success-color, 0.3);
        }

        &:hover {
            color: transparentize(#fff, .2);
            background-color: transparentize($success-color, .2);
        }
    }

}

@include template(".btn--primary", 'background-color', 0, 'color');
@include template(".btn--primary:hover", 'background-color', 0.3, 'color');