.alert {
	padding:1rem;
	font-size:1rem;
	margin-bottom:1rem;
	color:$base-bg-color;
	background-color: transparentize( $base-color, 0.5 );
	border-radius:0.13rem;

	&--warning {
		background-color: transparentize($warning-color,0.1);
	}
	&--info {
		background-color: transparentize($info-color,0.1);
	}
	&--success {
		background-color: transparentize($success-color,0.1);
		color:#fff;
	}
	&--danger {
		background-color: transparentize($danger-color,0.1);
		color:#fff;
	}

}