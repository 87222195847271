.user-icon {
    font-weight: bold;
    font-size: 0.8rem;
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2.2rem;
    background-color: transparentize($secondary-color, 0.2);
    // color: transparentize($base-bg-color, 0.1);
    border-radius:0.13rem;
    text-align: center;
    
}

.user-icon-list {
    display: flex;
    flex-wrap: wrap;

    .user-icon {
        margin-top: 0.5rem;
        margin-right: 0.25rem;
    }
}

@include template('.user-icon','background-color',0.9)