.section {
    &:last-child {
        margin: 0;
    }

    // border-bottom: 1px solid transparentize($base-color, 0.9);
    margin-bottom: 2rem;

    &__group-title {
        font-size: 0.8rem;
        font-weight: 800;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: transparentize($base-color, 0.6);
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;

        @include btn(&);

    }

    &__pre-title-btns {
        a {
            color: transparentize($base-color, 0.7);    
        }
        font-weight: 400;
        color: transparentize($base-color, 0.7);
        padding-bottom:1rem;
        font-size: 0.8rem;
        margin-bottom: -1.2rem;

        span {
            cursor: pointer;
            border-bottom:1px solid transparentize($base-color, 1);
            transition: 0.25s border-bottom-color;
        }

        span:hover {
            border-bottom-color:transparentize($base-color, 0.7);
            
        }
    }

    &__title {
        font-weight: 800;
        font-size: 1.4rem;
        margin: 1rem 0 0.4rem 0;
        letter-spacing: 1px;


    }

    &__description {
        margin-bottom: 1rem;
        font-size: 0.9rem;
    }

    &__spacer {
        position: relative;
        width: 100%;
        height: 1px;
        // margin:1rem 0 2rem 0;
        background-color: transparentize($base-color, 0.9);
    }

}