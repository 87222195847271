.topnav {
    // padding: 1rem 0;
    background-color: transparentize( $base-bg-color,0.2 );
    margin-bottom:1rem;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index:1;

&__item,&__brand {
        font-size: 1.2rem;
        font-weight: $font-weight-bold;
        letter-spacing: 0.5px;
        display: inline-block;
        cursor: pointer;
        color: transparentize( $base-color, 0.2 );
        padding: 1rem;

        &:hover {
            color: transparentize( $base-color, 0 );
        }

        

    }

    &__brand {
    	padding:1rem 1rem 1rem 0;
    }
    &__item {
    	font-size:1.1rem;
		text-align: center;
		.active {
            color: transparentize( $base-color, 0 );
        }
    }
    
}