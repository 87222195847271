@import "reset";
@import "variables/main";
@import "grid";
@import "section";
@import "components/leftnav";
@import "components/topnav";
@import "components/card";
@import "components/alert";
@import "components/task-list";
@import "components/comments";
@import "components/button";
@import "components/modal";
@import "components/dropdown";
@import "components/users";
@import "components/tasklists-item";
@import "components/badge";
@import "components/notification";
@import "components/kanban";
@import "components/loader";

*,
::after,
::before {
    box-sizing: border-box;
}

body {
    padding-top:80px;
    font-family: $base-font-family;
    background-color: $base-bg-color;
    font-size: $base-font-size;
    color: $base-color;
    line-height: 1.5rem;
    font-weight: 400;
}

p {
}

input,
textarea,
select,
button {
    font-family: $base-font-family;
    font-size: $base-font-size;
    color: $base-color;
    outline:0;
}

html,
body,
.h-100 {
    height: 100%;
}

a {
    text-decoration: none;
    color: $base-color;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mt-3 {
    margin-top: 3rem;
}

.w-100 {
    width: 100%;
}

.d-flex {
    display: flex !important;
}
.d-none {
    display: none ;
}

.align-items-end {
    align-items: end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-center {
    justify-content: center !important;
}

.fw-bold {
    font-weight: 600;
}

.form-group {
    margin-bottom: 1rem;
}

.form-label {
    width: 100%;
    margin-bottom: 0.25rem;
    display: block;
}

.select2-dropdown {
    border: 1px solid #000;
    background: $base-bg-color;
}
@include template(".select2-results__option--highlighted.select2-results__option--selectable", "background-color",0.3,"color");



.select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin:0 0.5rem 0 0;
    border: 1px solid transparentize($base-color, 0.8);
    background-color: transparentize($base-color, 0.8);
}
.select2-container--default .select2-results__option {
    background-color: $base-bg-color;
    color: $base-color;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    padding:0.3rem;
    border-right: 1px solid transparentize($base-color, 0.8);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
    background-color: transparentize($base-color, 0.6);
}
@include template(".select2-results__option--selected","background-color",0.9);
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
    padding:0.3rem;
}
.select2-container {
    display: block;
}
.form-control,
.select2-container--default .select2-selection--multiple {
    width: 100%;
    border: none;
    border: 1px solid transparentize($base-color, 0.9);
    background-color: transparentize($base-color, 0.98);
    border-radius: 0.15rem;
    padding: 0.5rem;
    outline: none;

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: transparentize($base-color, 0.6);
        opacity: 1;
        /* Firefox */
    }
}

@include template(".turbolinks-progress-bar", "background-color");

.turbolinks-progress-bar {
    height: 2px;
}

.text-center {
    text-align: center;
}
.sortable-ghost {
    border:0;
}
.sortable-chosen {
    
    // border: 0;
    
    // background-color:transparentize($base-color, 0.1);
    
}
[data-href] {
    cursor: pointer;
}
.fs-08 {
    font-size: 0.8rem;
}









  
  